import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';

import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Head from 'next/head';
import es from 'antd/lib/locale/es_ES';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import I18n from '@i18n/index';
import store from '@app/store';

import packageJson from '../package.json';

import '../assets/css/global.css';
import '../assets/css/vars.css';

const persistor = persistStore(store);

const MyApp: any = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    window.onerror = function (errorMsg) {
      Sentry.captureException(errorMsg);
      return true;
    };
  }, []);

  return (
    <>
      <Head>
        <title>Backoffice - Time Jobs</title>
        <link rel="icon" href="/tj-mini-logo.svg" />
        <meta name="version" content={packageJson.version} />
        <script
          async
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&libraries=${process.env.NEXT_PUBLIC_GOOGLE_MAP_LIBRARIES}`}
        />
      </Head>
      <ConfigProvider locale={es}>
        <I18nextProvider i18n={I18n}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <link
                href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap"
                rel="stylesheet"
              />
              <Component {...pageProps} />
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </ConfigProvider>
    </>
  );
};

export default MyApp;
